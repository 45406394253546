var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fullScreenLoading,
          expression: "fullScreenLoading",
        },
      ],
      staticClass: "manager-container module-main-body",
    },
    [
      _c(
        "el-row",
        { staticClass: "module-content-top-bar" },
        [
          _c("el-col", { attrs: { sm: 8, md: 10, lg: 10 } }, [
            _c("h1", { staticClass: "top-bar-title" }, [
              _vm._v(_vm._s(_vm.contentTitle)),
            ]),
          ]),
          _c("el-col", { attrs: { sm: 16, md: 14, lg: 14 } }, [
            _c("div", { staticClass: "button-bar flex-end" }, [
              _c(
                "div",
                { staticClass: "search-bar" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "round-input",
                      attrs: { placeholder: "Search Ship Name" },
                      nativeOn: {
                        keypress: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlerSearchKey.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.searchKey,
                        callback: function ($$v) {
                          _vm.searchKey = $$v
                        },
                        expression: "searchKey",
                      },
                    },
                    [
                      !_vm.toggleIcon
                        ? _c("el-button", {
                            attrs: {
                              slot: "append",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.handlerSearchKey },
                            slot: "append",
                          })
                        : _vm._e(),
                      _vm.toggleIcon
                        ? _c("el-button", {
                            attrs: {
                              slot: "append",
                              type: "primary",
                              icon: "el-icon-close",
                            },
                            on: { click: _vm.onHandlClearSearch },
                            slot: "append",
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "button-block",
                  staticStyle: { "padding-top": "5px" },
                },
                [
                  _vm.showAddAndEdit
                    ? _c(
                        "el-button",
                        {
                          staticClass: "primary-button",
                          attrs: {
                            disabled: !_vm.selectedCurrentCompany.id,
                            icon: "el-icon-circle-plus",
                          },
                          on: { click: _vm.addShip },
                        },
                        [_vm._v(" " + _vm._s(this.$t("add_2")) + " ")]
                      )
                    : _vm._e(),
                  _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
                    ? _c(
                        "el-button",
                        {
                          staticClass: "second-button",
                          attrs: {
                            disabled: !_vm.selectedCurrentCompany.id,
                            icon: "el-icon-circle-plus",
                          },
                          on: {
                            click: function ($event) {
                              _vm.inviteVisible = true
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(this.$t("invite")) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "div-main-body-left-right module-content-main-view",
          attrs: { type: "flex", justify: "space-between", align: "middle" },
        },
        [
          _vm.hasSysAdminPermission
            ? _c(
                "el-col",
                { staticClass: "left", attrs: { sm: 10, md: 8, lg: 5 } },
                [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c("base-block-title", {
                        attrs: {
                          title: _vm.$t("company"),
                          "sub-title": _vm.companyList.length,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-divider", { staticClass: "el-divider" }),
                  _c(
                    "div",
                    { staticClass: "search-bar" },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "round-input",
                          attrs: { placeholder: "Search Company Name" },
                          nativeOn: {
                            keypress: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.onHandleSearchCompany.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.searchCompanyKey,
                            callback: function ($$v) {
                              _vm.searchCompanyKey = $$v
                            },
                            expression: "searchCompanyKey",
                          },
                        },
                        [
                          !_vm.toggleSearchCompanyIcon
                            ? _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.onHandleSearchCompany },
                                slot: "append",
                              })
                            : _vm._e(),
                          _vm.toggleSearchCompanyIcon
                            ? _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  type: "primary",
                                  icon: "el-icon-close",
                                },
                                on: { click: _vm.onHandlClearSearchCompany },
                                slot: "append",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "content" },
                    _vm._l(_vm.companyList, function (item) {
                      return _c("company-item-new", {
                        key: item.id,
                        attrs: {
                          company: item,
                          logoSrc: item.logoSrc,
                          showStatusTage: true,
                          currentCompany: _vm.selectedCurrentCompany,
                          active: item.id === _vm.selectedCurrentCompany.id,
                        },
                        on: {
                          "update:logoSrc": function ($event) {
                            return _vm.$set(item, "logoSrc", $event)
                          },
                          "update:logo-src": function ($event) {
                            return _vm.$set(item, "logoSrc", $event)
                          },
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onClickCompanyItem(item)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pageVisible && _vm.selectedCurrentCompany.id
            ? _c(
                "el-col",
                {
                  staticClass: "right",
                  attrs: {
                    sm: _vm.hasSysAdminPermission ? 14 : 24,
                    md: _vm.hasSysAdminPermission ? 16 : 24,
                    lg: _vm.hasSysAdminPermission ? 19 : 24,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "block",
                        height: "calc(100% - 0px)",
                        "box-shadow": "0 0 5px #eaeaea",
                        "border-radius": "10px",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.tableLoading,
                              expression: "tableLoading",
                            },
                          ],
                          ref: "refTable",
                          attrs: {
                            data: _vm.shipList,
                            height: "100%",
                            "row-key": "id",
                            "expand-row-keys": _vm.expandedRowsKey,
                            size: "small",
                          },
                          on: { "expand-change": _vm.handleChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "expand" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "flex-between-row" },
                                        _vm._l(
                                          _vm.expandCardShipFields,
                                          function (field) {
                                            return _c("span", { key: field }, [
                                              _c(
                                                "span",
                                                { staticClass: "flex-column" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "font-small",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          `${_vm.$t(field)}:`
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass: "font-small",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          `${
                                                            scope.row[field] ||
                                                            "_"
                                                          }`
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              761826032
                            ),
                          }),
                          _c(
                            "el-table-column",
                            {
                              attrs: { "min-width": "120px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "font-default" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.capitalizeFirstLetter(
                                                    scope.row.shipName,
                                                    "_"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1839807086
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("shipName"))),
                                ]),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "el-table-column",
                            {
                              attrs: { "min-width": "60px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "font-default" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.imo
                                                  ? scope.row.imo
                                                  : "_"
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1986116435
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("span", [_vm._v(_vm._s(_vm.$t("imo")))]),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "el-table-column",
                            {
                              attrs: { "min-width": "80px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "font-default" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row.licenceNo
                                                    ? scope.row.licenceNo
                                                    : "_"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1701327187
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("licenceNo"))),
                                ]),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "el-table-column",
                            {
                              attrs: { "min-width": "80px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "font-default" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row.registryPort
                                                    ? scope.row.registryPort
                                                    : "_"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2531835507
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("registryPort"))),
                                ]),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "el-table-column",
                            {
                              attrs: { "min-width": "60px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "font-default" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.type
                                                  ? scope.row.type
                                                  : "_"
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                641440435
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("span", [_vm._v(_vm._s(_vm.$t("type")))]),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "el-table-column",
                            {
                              attrs: { "min-width": "100px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.charterId
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "transfer-to-from",
                                              },
                                              [
                                                scope.row.isOwner
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "arrow-icon",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/images/action_icons/transfer_to.png"),
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "arrow-icon",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: require("@/assets/images/action_icons/transfer_from.png"),
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "font-default",
                                                    style: {
                                                      color: scope.row.isOwner
                                                        ? "#222BFF"
                                                        : "#FC7C05",
                                                      fontWeight: "400",
                                                      display: "block",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          scope.row
                                                            .transferToOrFrom
                                                            ? scope.row
                                                                .transferToOrFrom
                                                            : ""
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2165500724
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("transferredToOrFrom"))),
                                ]),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "el-table-column",
                            {
                              attrs: { "min-width": "100px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "p",
                                          { staticClass: "font-default" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row.operator
                                                    ? scope.row.operator
                                                    : "_"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3540299443
                              ),
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("operator"))),
                                ]),
                              ]),
                            ],
                            2
                          ),
                          _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
                            ? _c(
                                "el-table-column",
                                {
                                  attrs: { width: "100px" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "flex-between-row",
                                              },
                                              [
                                                [
                                                  scope.row.isOwner
                                                    ? _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Edit Ship",
                                                            placement:
                                                              "top-end",
                                                          },
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            attrs: {
                                                              size: "small",
                                                              icon: "el-icon-edit",
                                                              circle: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                {
                                                                  _vm.editOrViewShip(
                                                                    scope.row,
                                                                    false
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "View Ship",
                                                            placement:
                                                              "top-end",
                                                          },
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            attrs: {
                                                              size: "small",
                                                              icon: "el-icon-view",
                                                              circle: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                {
                                                                  _vm.editOrViewShip(
                                                                    scope.row,
                                                                    true
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                ],
                                                [
                                                  scope.row.charterId == null
                                                    ? _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Transfer Ship",
                                                            placement:
                                                              "top-end",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                size: "small",
                                                                circle: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    {
                                                                      _vm.showTransferShipDialog(
                                                                        scope.row
                                                                      )
                                                                    }
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _c("img", {
                                                                  staticStyle: {
                                                                    height:
                                                                      "12px",
                                                                    width:
                                                                      "12px",
                                                                  },
                                                                  attrs: {
                                                                    src: require("@/assets/images/action_icons/transfer_action_icon.png"),
                                                                    alt: "",
                                                                  },
                                                                }),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass: "item",
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Stop Current Transfer",
                                                            placement:
                                                              "top-end",
                                                          },
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            attrs: {
                                                              size: "small",
                                                              icon: "el-icon-finished",
                                                              circle: "",
                                                              disabled:
                                                                !scope.row
                                                                  .isOwner,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                {
                                                                  _vm.stopTransferShip(
                                                                    scope.row
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                ],
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3984788148
                                  ),
                                },
                                [_c("template", { slot: "header" })],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.editShipVisible && !_vm.fullScreenLoading
        ? _c("compact-form-add-or-edit", {
            ref: "addOrEdit",
            attrs: {
              title: "ship",
              visible: _vm.editShipVisible,
              "is-view-only": _vm.isOnlyViewShipFormField,
              "url-save": _vm.saveUrl,
              "url-update": _vm.editUrl,
              fields: _vm.shipFormFields,
              rules: _vm.shipFormRules,
              width: "70%",
              top: "5vh",
              labelWidthCn: "100",
              labelWidthEn: "200",
              backupObj: _vm.backupObj,
            },
            on: {
              "update:visible": function ($event) {
                _vm.editShipVisible = $event
              },
              "update:isViewOnly": function ($event) {
                _vm.isOnlyViewShipFormField = $event
              },
              "update:is-view-only": function ($event) {
                _vm.isOnlyViewShipFormField = $event
              },
              closed: _vm.hanleClosedEditAndAddPopup,
              "submit-success": _vm.getShipListByCompany,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.inviteVisible,
            "close-on-click-modal": false,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.inviteVisible = $event
            },
          },
        },
        [
          _c("base-title", {
            attrs: { slot: "title", text: this.$t("invite") },
            slot: "title",
          }),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "el-form-item-left-lable",
              staticStyle: { padding: "0 20px" },
              attrs: {
                model: _vm.inviteFormData,
                rules: _vm.inviteFormRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("shipName"), prop: "shipName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.inviteFormData.shipName,
                      callback: function ($$v) {
                        _vm.$set(_vm.inviteFormData, "shipName", $$v)
                      },
                      expression: "inviteFormData.shipName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("imo"), prop: "imo" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.inviteFormData.imo,
                      callback: function ($$v) {
                        _vm.$set(_vm.inviteFormData, "imo", $$v)
                      },
                      expression: "inviteFormData.imo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("licenseNumber"),
                    prop: "licenseNumber",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.inviteFormData.licenseNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.inviteFormData, "licenseNumber", $$v)
                      },
                      expression: "inviteFormData.licenseNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("email"), prop: "email" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.inviteFormData.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.inviteFormData, "email", $$v)
                      },
                      expression: "inviteFormData.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function ($event) {
                      _vm.inviteVisible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.inviteShip },
                },
                [_vm._v(" " + _vm._s(_vm.$t("invite")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.transferShipDialogVisible,
            "close-on-click-modal": false,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.transferShipDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
            _c(
              "span",
              {
                staticStyle: {
                  "font-weight": "400",
                  "margin-right": "10px",
                  "font-size": "12",
                },
              },
              [_vm._v(_vm._s(_vm.$t("imo")) + ":")]
            ),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.selectedTransferShip && _vm.selectedTransferShip.imo
                    ? _vm.selectedTransferShip.imo
                    : "-"
                )
              ),
            ]),
          ]),
          _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
            _c(
              "span",
              {
                staticStyle: {
                  "font-weight": "400",
                  "margin-right": "10px",
                  "font-size": "12",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("shipName")) + ":")]
            ),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.selectedTransferShip && _vm.selectedTransferShip.shipName
                    ? _vm.selectedTransferShip.shipName
                    : "-"
                )
              ),
            ]),
          ]),
          _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
            _c(
              "span",
              {
                staticStyle: {
                  "font-weight": "400",
                  "margin-right": "10px",
                  "font-size": "12",
                },
              },
              [_vm._v(_vm._s(_vm.$t("email")) + ":")]
            ),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.selectedTransferShip && _vm.selectedTransferShip.email
                    ? _vm.selectedTransferShip.email
                    : "-"
                )
              ),
            ]),
          ]),
          _c(
            "el-form",
            { ref: "transferShipForm" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "500px",
                    height: "20px",
                    display: "flex",
                    "flex-direction": "row",
                    "justify-content": "start",
                    "font-size": "14.5",
                    "font-weight": "500",
                  },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("* ")]),
                  _vm._v(" " + _vm._s(_vm.$t("transferTo")) + " "),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "transferToOrganizationId",
                    rules: { required: true, trigger: "blur" },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "Please enter a keyword",
                        "remote-method": _vm.getTransferToCompanyOpts,
                        loading: _vm.selectOpstSearchLoading,
                      },
                      model: {
                        value: _vm.transferShipFormData.organizationId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.transferShipFormData,
                            "organizationId",
                            $$v
                          )
                        },
                        expression: "transferShipFormData.organizationId",
                      },
                    },
                    _vm._l(_vm.transferToCompanyOpst, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function ($event) {
                      _vm.transferShipDialogVisible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function ($event) {
                      return _vm.handleTransferOrTerminateShip(true)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("startTransfer")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }