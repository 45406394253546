<template>
  <el-dialog
    v-fullscreen
    custom-class="base-form"
    :visible.sync="dialogVisible"
    :width="width"
    :top="top"
    :close-on-click-modal="false"
    v-on="$listeners"
  >
    <span slot="title" class="base-title-font">
      <em
        :style="{ color: $store.state.currentTheme.primaryColor }"
        class="iconfont icon-yuandianzhong"
      >
      </em>
      {{
        isViewOnly
          ? "View " + $t(title) + " Details"
          : $t(formData.id ? "edit" : "add") +
            " " +
            $t(title) +
            `${isCropStamp ? " - Stamp Upload" : ""}`
      }}
    </span>
    <div class="body">
      <el-form
        v-show="!isCropStamp"
        ref="form"
        :model="formData"
        :rules="rules"
        :inline="true"
        :label-position="'top'"
      >
        <template v-for="item in fields">
          <el-form-item
            :class="item.className && item.className"
            v-if="item.fieldType !== 'invisible'"
            :label="$t(item.label || item.fieldName)"
            :prop="item.fieldName"
            :key="item.fieldName"
            :rules="getRules(item)"
          >
            <!--普通输入框-->
            <el-input
              v-if="
                !item.formConfig || ['input', 'password'].includes(item.formConfig.type)
              "
              v-model="formData[item.fieldName]"
              :show-password="item.formConfig && item.formConfig.type === 'password'"
              auto-complete="new-password"
              :disabled="isViewOnly || item.disabled"
              @change="
                (e) =>
                  item.changeEvent
                    ? item.changeEvent(formData, item.fieldName, e)
                    : () => {}
              "
            >
            </el-input>
            <phone-input
              :disabled="isViewOnly || item.disabled"
              v-else-if="item.formConfig.type === 'phone'"
              v-model="formData[item.fieldName]"
            >
            </phone-input>
            <!--数字输入框-->
            <el-input
              v-else-if="item.formConfig.type === 'inputNumber'"
              v-model.number="formData[item.fieldName]"
              :min="item.formConfig.min || -Infinity"
              :max="item.formConfig.max || Infinity"
              :disabled="isViewOnly || item.disabled"
            >
            </el-input>
            <!--单选组-->
            <el-radio-group
              v-else-if="item.formConfig.type === 'radio'"
              v-model="formData[item.fieldName]"
              :disabled="isViewOnly || item.disabled"
            >
              <el-radio
                v-for="(option, index) in item.formConfig.options"
                :key="index"
                :label="option.value"
              >
                {{ $t(option.text) }}
              </el-radio>
            </el-radio-group>
            <!--多选组-->
            <el-checkbox-group
              v-else-if="item.formConfig.type === 'checkbox'"
              v-model="formData[item.fieldName]"
              :disabled="isViewOnly || item.disabled"
            >
              <el-checkbox
                v-for="(option, index) in item.formConfig.options"
                :key="index"
                :label="option.value"
              >
                {{ $t(option.text) }}
              </el-checkbox>
            </el-checkbox-group>
            <!--下拉选择框-->
            <el-select
              filterable
              v-else-if="item.formConfig.type === 'select'"
              v-model="formData[item.fieldName]"
              :disabled="isViewOnly || item.disabled"
            >
              <el-option
                v-for="option in item.formConfig.options"
                :key="option.value"
                :label="option.label || option.value"
                :value="option.value"
              >
              </el-option>
            </el-select>
            <!--级联选择器-->
            <el-cascader
              v-else-if="item.formConfig.type === 'cascader'"
              v-model="formData[item.fieldName]"
              :options="item.formConfig.options"
              :show-all-levels="
                item.formConfig.showAllLevels === undefined
                  ? true
                  : item.formConfig.showAllLevels
              "
              :props="item.formConfig.props"
              :disabled="isViewOnly || item.disabled"
            >
            </el-cascader>
            <!--开关-->
            <el-switch
              v-else-if="item.formConfig.type === 'switch'"
              v-model="formData[item.fieldName]"
              :active-text="item.formConfig.activeText || ''"
              :inactive-text="item.formConfig.inactiveText || ''"
              :disabled="isViewOnly || item.disabled"
              @change="item.changeEvent ? item.changeEvent($event) : () => {}"
            >
            </el-switch>
            <!--日期选择器-->
            <el-date-picker
              v-else-if="['date', 'datetime'].includes(item.formConfig.type)"
              v-model="formData[item.fieldName]"
              :type="item.formConfig.type"
              :value-format="
                item.formConfig.type === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss'
              "
              :disabled="isViewOnly || item.disabled"
            >
            </el-date-picker>
            <!--时间选择器-->
            <el-time-picker
              v-else-if="item.formConfig.type === 'time'"
              v-model="formData[item.fieldName]"
              value-format="HH:mm:ss"
              :disabled="isViewOnly || item.disabled"
            >
            </el-time-picker>
            <!--图标选择器-->
            <icon-picker
              v-else-if="item.formConfig.type === 'icon'"
              v-model="formData[item.fieldName]"
              :disabled="isViewOnly || item.disabled"
            >
            </icon-picker>
            <div v-else-if="item.formConfig.type === 'stamp-upload'">
              <el-upload
                :disabled="isViewOnly || item.disabled"
                :action="fileUploadUrl"
                accept=".jpg,.png"
                list-type="picture"
                :file-list="item.formConfig.fileList ? item.formConfig.fileList : []"
                :on-change="onUploadStamp"
                :auto-upload="false"
              >
                <el-button
                  v-if="isViewOnly || !item.disabled"
                  size="mini"
                  type="primary"
                  plain
                  round
                  style="min-width: 200px"
                  >{{ item.formConfig.desc }}</el-button
                >
                <div slot="tip" class="el-upload__tip" style="margin: 0">
                  {{ item.formConfig.message }}
                </div>
              </el-upload>
              <div class="div-stamp-preview">
                <img
                  v-if="item.formConfig.fileList && item.formConfig.fileList[0]"
                  :src="item.formConfig.fileList[0].url"
                />
                <div v-else>No stamp uploaded</div>
              </div>
            </div>
          </el-form-item>
        </template>
      </el-form>
      <div v-show="isCropStamp" v-loading="isStampLoading">
        <div style="min-height: 100px; display: flex; justify-content: center">
          <cropper
            ref="cropper"
            class="cropper"
            :src="(stampFile && stampFile.url) || ''"
            @change="handleStampCropChange"
            @ready="handleCropperReady"
          />
        </div>
      </div>
    </div>
    <div slot="footer" v-if="!isCropStamp">
      <el-button size="small" round @click="dialogVisible = false">
        {{ isViewOnly ? $t("close") : $t("cancel") }}
      </el-button>
      <el-button
        :disabled="isViewOnly"
        size="small"
        round
        type="primary"
        :loading="loading"
        @click="submit"
      >
        {{ $t("submit") }}
      </el-button>
    </div>
    <div slot="footer" v-else-if="!isStampLoading">
      <el-button size="small" round @click="isCropStamp = false">
        {{ isViewOnly ? $t("close") : $t("cancel") }}
      </el-button>
      <el-button
        :disabled="isViewOnly"
        size="small"
        round
        type="primary"
        @click="handleUploadStampOnClick"
        :loading="isStampBtnLoading"
      >
        {{ $t("upload") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import IconPicker from "@/components/IconPicker";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import {
  dataURLtoBlob,
  displayImgCropperRotateBtn,
  getObjectDiff,
  capitalizeFirstLetter,
} from "@/utils";
export default {
  name: "CompactFormAddOrEdit",
  components: {
    IconPicker,
    Cropper,
  },
  computed: {
    labelWidth() {
      if (this.$i18n.locale === "cn") {
        return this.$attrs.labelWidthCn ? `${this.$attrs.labelWidthCn}px` : "80px";
      }
      return this.$attrs.labelWidthEn ? `${this.$attrs.labelWidthEn}px` : "160px";
    },
    // formRules () {
    //   const formRules = this.rules
    //   if (this.title === 'ship') {
    //     if (this.rules?.imo?.length === 2) {
    //       formRules.imo[0].required = this?.backupObj?.imo
    //     }
    //     if (this.rules?.licenceNo?.length === 2) {
    //       formRules.licenceNo[0].required = this?.backupObj?.licenceNo
    //     }
    //   }
    //   return formRules
    // }
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
    rules: {
      type: Object,
      default: () => {
        return {};
      },
    },
    urlSave: {
      type: String,
      required: true,
    },
    urlUpdate: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "40%",
    },
    top: {
      type: String,
      default: "10vh",
    },
    isViewOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    backupObj: {
      type: Object,
      required: false,
    },
  },
  data() {
    const formData = {};
    this.fields.forEach((field) => {
      formData[field.fieldName] = field.default === undefined ? undefined : field.default;
    });
    return {
      formData,
      dialogVisible: false,
      loading: false,
      fileUploadUrl: null,
      isCropStamp: false,
      stampFile: null,
      croppedStampCanvas: null,
      croppedStampCoordinates: null,
      isStampLoading: false,
      isStampBtnLoading: false,
      stampField: this.fields.find((f) => {
        return f.fieldName === "stampId";
      }),
    };
  },
  mounted() {
    this.dialogVisible = this.$attrs.visible;
    this.fileUploadUrl = this.$apis.baseUrl + this.$apis.uploadFile;
  },
  methods: {
    dataURLtoBlob,
    getObjectDiff,
    capitalizeFirstLetter,
    getRules(item) {
      /**
       * 获取校验规则
       */
      const originalRules = item.rules;
      if (originalRules) {
        // 如果存在验证规则，继续操作
        if (originalRules === "required") {
          return [
            {
              required: true,
              message: this.$t("rejectBeEmpty"),
              trigger: "change",
            },
          ];
        } else if (typeof originalRules === "object") {
          return originalRules;
        }
      }
      return undefined;
    },
    handleCropperReady() {
      this.isStampLoading = false;
      displayImgCropperRotateBtn(this.$refs.cropper);
    },
    handleUploadStampOnClick() {
      this.isStampBtnLoading = true;
      const dataUrl = this.croppedStampCanvas.toDataURL(this.stampFile.raw.type);
      const blob = this.dataURLtoBlob(dataUrl);
      const file = new File([blob], this.stampFile.name);

      const formData = new FormData();
      formData.append("file", file);

      this.$request
        .post({
          url: this.$apis.uploadShipStamp,
          data: formData,
        })
        .then((res) => {
          this.stampField.formConfig.fileList = [];
          this.$set(this.stampField.formConfig.fileList, 0, res.data);
          this.stampField.formConfig.fileList[0].url = URL.createObjectURL(blob);
          this.isCropStamp = false;
          this.$message.success(this.$t("saveSuccess"));
        });
    },
    onUploadStamp(file) {
      this.isStampLoading = true;
      this.stampFile = file;
      this.isCropStamp = true;
      this.isStampBtnLoading = false;
    },
    handleStampCropChange({ coordinates, canvas }) {
      this.croppedStampCanvas = canvas;
      this.croppedStampCoordinates = coordinates;
    },
    submit() {
      /**
       * 提交数据
       */

      const url = this.formData.id ? this.urlUpdate : this.urlSave;
      onsubmit = () => {
        this.loading = true;
        Object.keys(this.formData).forEach((key) => {
          if (key === "stampId") {
            this.formData[key] =
              this.stampField.formConfig.fileList &&
              this.stampField.formConfig.fileList.length > 0
                ? this.stampField.formConfig.fileList[0].id
                : null;
          }
          // if (this.formData[key] === null) {
          //   delete this.formData[key];
          // }
          if (
            typeof this.formData[key] === "string" &&
            this.formData[key]?.trim().length < 1
          ) {
            this.formData[key] = null;
          }
        });
        this.$request
          .post({
            url,
            data: this.formData,
          })
          .then((data) => {
            if (data?.code === 1000) {
              this.$emit("submit-success");
              this.$emit("update:visible", false);
              this.$message.success(this.$t("saveSuccess"));
              this.dialogVisible = false;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.$props.hasOwnProperty("backupObj")) {
            const currentObj = {};
            Object.keys(this.backupObj).forEach((key) => {
              currentObj[key] = this.formData[key];
            });
            const differentProperty = getObjectDiff(this.backupObj, currentObj);
            if (differentProperty.length > 0) {
              const msg = capitalizeFirstLetter(
                differentProperty.join(",")?.replace("N", " n")
              );
              this.$confirm(`${msg} Changes Detected. Continue?`, "Warning", {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning",
              })
                .then(() => {
                  onsubmit();
                })
                .catch(() => {});
            } else {
              onsubmit();
            }
          } else {
            onsubmit();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 0px;
  word-break: break-all;
  overflow-y: auto;
  height: fit-content;
}
/deep/.el-form-item__content {
  width: 230px;
  .el-switch {
    top: -10px;
  }
}
/deep/.el-form-item__label {
  float: left !important;
  font-size: 12px;
  line-height: 20px;
  padding: 0px;
}
/deep/.el-select {
  width: 100%;
}
// /deep/.el-input__inner{
//   font-size: 12px;
// }
.base-form {
  .body {
    padding: 10px 0px 0px 20px;
    .one-line-item {
      display: block;
      /deep/.el-form-item__content {
        width: 100%;
      }
      /deep/.el-form-item__label {
        width: 230px;
      }
    }
    .upload-preview {
      display: block;
      padding: 10px 0;
      /deep/.el-form-item__label {
        width: 100%;
      }
      /deep/.el-form-item__content {
        width: 100%;
      }
    }
    .selet-input {
      width: 230px;
      margin-right: 10px;
    }
  }
}
.div-stamp-preview {
  height: 150px;
  width: 150px;
  margin: 4px 20px;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    max-height: 150px;
    max-width: 150px;
  }
  > div {
    word-break: break-word;
    text-align: center;
  }
}
.cropper {
  height: 600px;
  width: 600px;
  border: 1px solid gray;
}
/deep/.vue-advanced-cropper__background {
  background: white;
}
/deep/.vue-advanced-cropper__foreground {
  background: white;
}
/deep/.vue-line-wrapper.vue-line-wrapper--south,
/deep/.vue-line-wrapper.vue-line-wrapper--north {
  background: darkgray;
  height: 2px;
}
/deep/.vue-line-wrapper.vue-line-wrapper--east,
/deep/.vue-line-wrapper.vue-line-wrapper--west {
  background: rgb(135, 135, 135);
  width: 2px;
}
/deep/.vue-simple-handler {
  background: rgb(88, 88, 88);
}
/deep/.el-upload-list {
  display: none;
}
/deep/.el-upload > div {
  display: none;
}
</style>
